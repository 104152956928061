import React from 'react';
import Card from '../components/Card';
import Layout from '../components/Layout';
import ContentWrapper from '../components/ContentWrapper';
import SEO from '../components/Seo';
import { Link } from "gatsby"
import BirdButton from '../components/BirdButton'
import { useState } from 'react';

const agreements = [
    "Noncompete",
    "Scope of Work",
    "Open-End",
    "Custom Terms",
    "Materials",
    "Price Breakdown",
    "Time and Materials Contracts",
    "Not sure yet",
];
const FORMSPARK_ACTION_URL = "https://submit-form.com/r9FFN2rI";
function Contact() {
    const [formInfo, setFormInfo] = useState({})
    const onSubmit = async (e) => {
        e.preventDefault();
        await fetch(FORMSPARK_ACTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(
                formInfo,
            ),
        });
    };
    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    const validatePhone = (phone) => {
        const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return re.test(String(phone).toLowerCase());
    };
    const getEmailClassName = (email) => {
        if (validateEmail(email)) {
            return "form-control";
        } else {
            return "form-control error";
        }
    };

    const getPhoneClassName = (phone) => {
        if (validatePhone(phone)) {
            return "form-control";
        } else {
            return "form-control error";
        }
    };

    return (
        <Layout className="contact">
            <SEO
                url="https://www.turinglamarr.com/contact"
                title="Turring Lamarr contact"
                description='contact us page for Turing Lamarr'
                image="/static/78f4a3519376fda1775222d13c6edd62/b7ef5/RobotFocusAtNight.webp"
            />
            <ContentWrapper>

                <form>
                    <h1>Contact Us</h1>
                    <small id="emailHelp" className="form-text text-muted">We'll never share your info with anyone else.</small>
                    <Card>
                        <div className='form-group'>
                            <label>name</label>
                            <input type="text" className={formInfo.name ? '' : 'error'} placeholder="Hedy Lamarr" onChange={({ target: { value: name } }) => setFormInfo({
                                ...formInfo,
                                name
                            })} />
                        </div>
                    </Card>
                    <Card>
                        <div className="form-group">
                            <label htmlFor="email">email</label>
                            <input type="email" className={getEmailClassName(formInfo.email)} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="alanturing@turinglamarr.com" onChange={({ target: { value: email } }) => {
                                if (validateEmail(email)) {
                                    setFormInfo({
                                        ...formInfo,
                                        email
                                    })
                                } else {
                                    setFormInfo({
                                        ...formInfo,
                                        email: ''
                                    })
                                }
                            }} />

                        </div>
                    </Card>
                    <Card>
                        <div className="form-group">
                            <label htmlFor="phone">phone</label>
                            <input type="tel" className={getPhoneClassName(formInfo.phone)} id="phone" aria-describedby="phone" placeholder="1 (888) 888 8888" onChange={({ target: { value: phone } }) => {
                                if (validatePhone(phone)) {
                                    setFormInfo({
                                        ...formInfo,
                                        phone
                                    })
                                } else {
                                    setFormInfo({
                                        ...formInfo,
                                        phone: ''
                                    })
                                }
                            }} />
                        </div>
                    </Card>
                    <Card>
                        <div className="form-group">
                            <label htmlFor="company">company</label>
                            <input type="text" className="form-control" id="company" aria-describedby="company" placeholder="Turing Lamarr" onChange={({ target: { value: company } }) => setFormInfo({
                                ...formInfo,
                                company
                            })} />
                        </div>
                    </Card>
                    <Card>
                        <div className="form-group">
                            <label htmlFor="message">message</label>
                            <textarea onChange={({ target: { value: message } }) => setFormInfo({
                                ...formInfo,
                                message
                            })} type="text" className="form-control" id="message" placeholder="Lets begin ...." />
                            <small id="messageHelp" className="form-text text-muted">If you would like to work with us this is the place to tell us what you are building, need, or want.</small>

                        </div>
                    </Card>
                    <Card>
                        <div className="form-group contact__agreements">
                            <label htmlFor="agreements">agreements</label>
                            {
                                agreements.map((agreement) => (
                                    <div className="contact__agreements-checkbox" key={agreement}>
                                        <input type="checkbox" className="form-control" id={agreement} onClick={() => setFormInfo({
                                            ...formInfo,
                                            [agreement]: !formInfo[agreement]
                                        })} />
                                        <label htmlFor="nda">{agreement}</label>
                                    </div>
                                ))
                            }
                            <small>check all that apply</small>
                        </div>
                    </Card>
                    <Card>
                        <div className="form-group contact__nda">
                            <label htmlFor="agreements">legal</label>
                            <div className="contact__agreements-checkbox">
                                <input type="checkbox" className="form-control" id="nda" onClick={() => setFormInfo({
                                    ...formInfo,
                                    nda: !formInfo.nda
                                })} />
                                <label htmlFor="nda">  I agree to the <Link to="/nda/">basic non disclosure</Link></label>
                            </div>
                            <small>this is courtesy for you, we know there aren't any signatures, but we stand by it</small>

                        </div>
                    </Card>
                    <BirdButton disabled={!formInfo.name && !formInfo.phone && !formInfo.email} onClick={onSubmit} />
                </form>
            </ContentWrapper>
        </Layout>
    );
}

export default Contact;
