import React, { useEffect, useState } from 'react'

const BirdButton = ({ disabled, onClick, text = "SEND", activeText = "We will be in touch" }) => {
    const [sending, setSending] = useState(false);
    const buttonClassNames = sending ? 'button-bird active' : 'button-bird';
    const buttonTextClassNames = sending ? 'button-bird__text active' : 'button-bird__text';
    const buttonText = sending ? activeText : text;
    return (
        <div className="wrapper-no4">
            <button disabled={disabled} className={buttonClassNames} onClick={disabled ? (e) => { e.preventDefault() } : e => {
                e.preventDefault()
                setSending(true);
                onClick(e)
            }}>
                <p className={buttonTextClassNames}>{buttonText}</p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    className="feather"
                    enableBackground="new 0 0 75 38"
                    viewBox="0 0 75 38"
                >
                    <path d="M20.8 31.6c3.1-.7 2.9-2.3 2 1 9.1 4.4 20.4 3.7 29.1-.8.7-2.1 1-3.9 1-3.9.6.8.8 1.7 1 2.9 4.1-2.3 7.6-5.3 10.2-8.3.4-2.2.4-4 .4-4.1.6.4.9 1.2 1.2 2.1 4.5-6.1 5.4-11.2 3.7-13.5 1.1-2.6 1.6-5.4 1.2-7.7-.5 2.4-1.2 4.7-2.1 7.1-5.8 11.5-16.9 21.9-30.3 25.3 13-4 23.6-14.4 29.1-25.6C62.8 9 55.6 16.5 44.7 20.7c2.1.7 3.5 1.1 3.5 1.6-.1.4-1.3.6-3.2.4-7-.9-7.1 1.2-16 1.5 1 1.3 2 2.5 3.1 3.6-1.9-.9-3.8-2.2-5.6-3.6-.9.1-10.3 4.9-22.6-12.3 2 5.8 7.9 15 16.9 19.7z"></path>
                </svg>
                <span className="bird"></span>
                <span className="bird--1"></span>
                <span className="bird--2"></span>
                <span className="bird--3"></span>
                <span className="bird--4"></span>
                <span className="bird--5"></span>
                <span className="bird--6"></span>
                <span className="bird--7"></span>
                <span className="bird--8"></span>
                <span className="bird--9"></span>
                <span className="bird--10"></span>
                <span className="bird--11"></span>
                <span className="bird--12"></span>
                <span className="bird--13"></span>
                <span className="bird--14"></span>
                <span className="bird--15"></span>
                <span className="bird--16"></span>
                <span className="bird--17"></span>
                <span className="bird--18"></span>
                <span className="bird--19"></span>
                <span className="bird--20"></span>
                <span className="bird--21"></span>
                <span className="bird--22"></span>
                <span className="bird--23"></span>
                <span className="bird--24"></span>
                <span className="bird--25"></span>
                <span className="bird--26"></span>
                <span className="bird--27"></span>
                <span className="bird--28"></span>
                <span className="bird--29"></span>
                <span className="bird--30"></span>
            </button>
        </div>

    )
}

export default BirdButton