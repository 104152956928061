import React from 'react';
const Card = ({ className = '', children, ...props }) => {
    return (
        <div className={`card ${className} `} {...props}>
            <div className="card__content">
                {children}
            </div>
        </div>
    );

}

export default Card;